import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./AddSocialProfilesModal.scss";

import { uuidv7 as uuid } from "uuidv7";
import { injectIntl, IntlShape } from "react-intl";

import axios from "axios";
import {
  CUSTOM_URL_REGEX,
  REGEX_BY_PROVIDER,
  SocialProvider,
  SocialProviders,
  SocialProfileStatus,
  SocialProfileFlags,
} from "src/types";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { WSModal } from "src/app/components/WSModal/WSModal";
import { translateMessage } from "src/app/methods/translateMessage";
import { showErrorToast } from "src/utils/methods";
import CustomInput from "../../components/CustomInput/CustomInput";
import { ReactComponent as LinkIcon } from "../../../images/link.svg";
import { ReactComponent as TrashCanIcon } from "../../../images/trash-can.svg";
import { API_URLS } from "../../../utils/API_URLS";
import { showToast } from "../../methods/showToast";
import { AddSocialProfilesModalButtons } from "./AddSocialProfilesModalButtons";

export interface SocialProfile {
  socialId: string | null;
  socialProvider: string;
  socialUrl: string;
  socialUsername: string;
  status: SocialProfileStatus;
  flags: SocialProfileFlags[];
}

interface CustomUrl {
  id: string;
  value: string;
}

interface Props {
  objectType: "task" | "global-task";
  onClose: () => void;
  name: string;
  taskId: string;
  socialProfiles?: SocialProfile[];
  intl: IntlShape;
}

export const initialStateSocialProviders = {
  instagram: "",
  tiktok: "",
  youtube: "",
  twitter: "",
  discord: "",
  twitch: "",
  snapchat: "",
  facebook: "",
  telegram: "",
};

function AddSocialProfilesModal(props: Props) {
  const { objectType, onClose, name, taskId, socialProfiles } = props;

  const [profiles, setProfiles] = useState<{ [key: string]: string }>(
    initialStateSocialProviders,
  );

  const [customUrls, setCustomUrls] = useState<CustomUrl[]>([]);
  const [scrollModalBodyToBottom, setScrollModalBodyToBottom] = useState(false);

  const getUrl = () => {
    if (objectType === "global-task") {
      return API_URLS.setGlobalTaskMetaValueSocialProfiles;
    }

    return API_URLS.setTaskMetaValueSocialProfiles;
  };

  const handleAddSocialLink = () => {
    setCustomUrls((urls) => [...urls, { id: uuid(), value: "" }]);
    setScrollModalBodyToBottom(true);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const filteredMedia = SocialProviders.filter(
      (item) => profiles[item.value]?.length,
    );

    let shouldBreak = false;
    filteredMedia.forEach((item) => {
      // @ts-ignore
      if (!REGEX_BY_PROVIDER[item.value].test(profiles[item.value])) {
        showToast(
          "error",
          <IDHFormattedMessage
            id="ws_invalid_url"
            defaultMessage="Invalid URL"
          />,
          <IDHFormattedMessage
            id="ws_provided_url_not_valid"
            defaultMessage="Provided URL is not valid."
          />,
        );
        shouldBreak = true;
      }
    });

    customUrls.forEach((item: { id: string; value: string }) => {
      if (!CUSTOM_URL_REGEX.test(item.value)) {
        showToast(
          "error",
          <IDHFormattedMessage
            id="ws_invalid_url"
            defaultMessage="Invalid URL"
          />,
          <IDHFormattedMessage
            id="ws_provided_custom_url_not_valid"
            defaultMessage="Provided custom URL is not valid."
          />,
        );
        shouldBreak = true;
      }
    });

    if (shouldBreak) return;

    const url = getUrl();

    axios
      .put(url, {
        uuid: taskId,
        socialProfiles: [
          ...filteredMedia.map((media: { value: string }) => ({
            provider: media.value,
            url: profiles[media.value],
          })),
          ...customUrls.map((url: { value: string }) => ({
            provider: SocialProvider.Custom,
            url: url.value,
          })),
        ],
      })
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error(error);
        showErrorToast();
      });
  };

  useEffect(() => {
    if (socialProfiles?.length) {
      const defaultProfiles = socialProfiles
        .filter((profile) => profile.socialProvider !== SocialProvider.Custom)
        ?.reduce(
          (p, profile) => ({
            ...p,
            [profile.socialProvider]: profile.socialUrl,
          }),
          {},
        );
      setProfiles(defaultProfiles ?? {});

      const customProfiles = socialProfiles
        .filter((profile) => profile.socialProvider === SocialProvider.Custom)
        ?.map((profile) => ({
          id: uuid(),
          value: profile.socialUrl,
        }));
      setCustomUrls(customProfiles ?? []);
    }
  }, [socialProfiles]);

  return (
    <WSModal
      className="add-social-profiles-modal"
      onClose={onClose}
      title={
        <IDHFormattedMessage
          defaultMessage="Add Social Profiles"
          id="ws_add_social_profiles"
        />
      }
      description={
        <span>
          <IDHFormattedMessage
            defaultMessage="Provide URL to social profiles"
            id="ws_provide_url_to"
          />{" "}
          <b>{name}</b>{" "}
          <IDHFormattedMessage
            defaultMessage="social profiles"
            id="ws_social_profiles"
          />
        </span>
      }
      customButton={
        <AddSocialProfilesModalButtons
          onClose={onClose}
          onConfirmClick={handleSubmit}
          handleAddSocialLink={handleAddSocialLink}
        />
      }
      scrollModalBodyToBottom={scrollModalBodyToBottom}
      scrollModalBodyToBottomCb={() => setScrollModalBodyToBottom(false)}
    >
      <form onSubmit={handleSubmit}>
        <div className="form-inputs-wrapper">
          {SocialProviders.map((social) => (
            <div className="input-wrapper">
              <img src={social.icon} alt={social.label} />
              <CustomInput
                value={profiles[social.value]}
                onChange={(e) =>
                  setProfiles((prevState: any) => ({
                    ...prevState,
                    [social.value]: e.target.value,
                  }))
                }
                placeholder={`${translateMessage({
                  intl: props.intl,
                  id: "ws_paste",
                  defaultMessage: "Paste",
                })} ${social.label} ${translateMessage({
                  intl: props.intl,
                  id: "ws_url_to_profile",
                  defaultMessage: "URL to profile",
                })}`}
              />
            </div>
          ))}

          {customUrls?.map((url: { id: string; value: string }) => (
            <SocialInput
              key={url.id}
              url={url}
              setUrls={setCustomUrls}
              intl={props.intl}
            />
          ))}
        </div>
      </form>
    </WSModal>
  );
}

export default injectIntl(AddSocialProfilesModal);

interface InputProps {
  url: {
    id: string;
    value: string;
  };
  setUrls: Dispatch<SetStateAction<any>>;
  intl: IntlShape;
}

const SocialInput: React.FC<InputProps> = (props) => {
  const { url, setUrls } = props;

  const [value, setValue] = useState(url.value);

  useEffect(() => {
    setUrls((urls: any) =>
      urls.map((item: any) => {
        if (item.id === url.id) {
          return { ...item, value };
        }
        return item;
      }),
    );
  }, [value]);

  const deleteUrl = () => {
    setUrls((urls: { id: string; value: string }[]) =>
      urls.filter((item: { id: string; value: string }) => {
        return item.id !== url.id;
      }),
    );
  };

  return (
    <div className="input-wrapper">
      <LinkIcon />
      <CustomInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={`${translateMessage({
          intl: props.intl,
          id: "ws_paste_url_to_profile",
          defaultMessage: "Paste URL to profile",
        })}`}
      />
      <TrashCanIcon className="trash-can" onClick={deleteUrl} />
    </div>
  );
};
